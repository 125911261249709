import React from 'react';
import Grid, { Column, Row } from '@components/core/Grid';
import Text from '@components/core/Text';
import Section from '@components/core/Section';
import Eyebrow from '@components/core/Eyebrow';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import Button from '@components/core/Button';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import ShareIcons from '@components/ShareIcons';
import styles from './detail-section.module.scss';

const DetailSection = ({ frontmatter, html, locationPath, discipline }) => {
    const {
        title,
        type,
        published,
        location,
        image,
        responsibilities,
        experience,
        applyLink,
    } = frontmatter;

    const { md: mediumScreen } = useBreakpoint();

    const renderApplyBtn = isMediumScreen => {
        if (!applyLink) {
            return null;
        }

        return (
            <div className={styles.wrapperApplyBtn}>
                <Button
                    className={styles.applyBtnMobile}
                    as="a"
                    href={applyLink}
                    target="_blank"
                    size={isMediumScreen ? null : 'block'}
                >
                    Apply on LinkedIn
                </Button>
            </div>
        );
    };

    return (
        <Section className={styles.wrapper}>
            <Grid>
                <Row>
                    <Column md="9">
                        <Eyebrow className={styles.eyebrow} casing="uppercase">
                            careers
                        </Eyebrow>
                        <Text className={styles.title} as="h2">
                            {title}
                        </Text>
                    </Column>
                    <Column md="3">
                        {mediumScreen && renderApplyBtn(mediumScreen)}
                    </Column>
                </Row>
                <Row>
                    <Column md="9">
                        <Text
                            className={styles.description}
                            as="div"
                            size="paragraphMd"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: html,
                            }}
                        />

                        {!mediumScreen && renderApplyBtn(mediumScreen)}
                        <Img
                            className={styles.image}
                            fluid={image.childImageSharp.fluid}
                        />
                    </Column>
                    <Column md="3">
                        <aside className={styles.aside}>
                            <ul className={styles.metadata}>
                                <li className={styles.metadataItem}>
                                    <Text
                                        size="paragraphXs"
                                        casing="uppercase"
                                        color="gray"
                                        as="h3"
                                    >
                                        Employment type
                                    </Text>
                                    <Text size="paragraphMd" as="p">
                                        {type}
                                    </Text>
                                </li>
                                <li className={styles.metadataItem}>
                                    <Text
                                        size="paragraphXs"
                                        casing="uppercase"
                                        color="gray"
                                        as="h3"
                                    >
                                        Discipline
                                    </Text>
                                    <Text size="paragraphMd" as="p">
                                        {discipline.discipline}
                                    </Text>
                                </li>
                                <li className={styles.metadataItem}>
                                    <Text
                                        size="paragraphXs"
                                        casing="uppercase"
                                        color="gray"
                                        as="h3"
                                    >
                                        Date Posted
                                    </Text>
                                    <Text size="paragraphMd" as="p">
                                        {published}
                                    </Text>
                                </li>
                                <li className={styles.metadataItem}>
                                    <Text
                                        size="paragraphXs"
                                        casing="uppercase"
                                        color="gray"
                                        as="h3"
                                    >
                                        Location
                                    </Text>
                                    <Text size="paragraphMd" as="p">
                                        {location}
                                    </Text>
                                </li>
                                <li className={styles.metadataItem}>
                                    <Text
                                        className={styles.shareTitle}
                                        size="paragraphMd"
                                        as="p"
                                    >
                                        share
                                    </Text>
                                    <ShareIcons
                                        locationPath={locationPath}
                                        title={title}
                                    />
                                </li>
                            </ul>
                        </aside>
                    </Column>
                    <Column md="8">
                        <div className={styles.jobDetails}>
                            {responsibilities && responsibilities.items && (
                                <section className={styles.descriptionSection}>
                                    <Text
                                        className={
                                            styles.descriptionSectionTitle
                                        }
                                        size="h5"
                                        as="h2"
                                    >
                                        {responsibilities.title}
                                    </Text>
                                    <ul
                                        className={
                                            styles.descriptionSectionList
                                        }
                                    >
                                        {responsibilities.items.map(item => (
                                            <Text
                                                className={
                                                    styles.descriptionSectionItem
                                                }
                                                key={item}
                                                as="li"
                                                size="paragraphMd"
                                            >
                                                {item}
                                            </Text>
                                        ))}
                                    </ul>
                                </section>
                            )}

                            {experience && experience.items && (
                                <section className={styles.descriptionSection}>
                                    <Text
                                        className={
                                            styles.descriptionSectionTitle
                                        }
                                        size="h5"
                                        as="h2"
                                    >
                                        {experience.title}
                                    </Text>
                                    <ul
                                        className={
                                            styles.descriptionSectionList
                                        }
                                    >
                                        {experience.items.map(item => (
                                            <Text
                                                className={
                                                    styles.descriptionSectionItem
                                                }
                                                key={item}
                                                as="li"
                                                size="paragraphMd"
                                            >
                                                {item}
                                            </Text>
                                        ))}
                                    </ul>
                                </section>
                            )}
                        </div>
                    </Column>
                </Row>
            </Grid>
        </Section>
    );
};

DetailSection.propTypes = {
    frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        published: PropTypes.string.isRequired,
        location: PropTypes.string.isRequired,
        image: PropTypes.shape({
            childImageSharp: PropTypes.shape({
                title: PropTypes.string,
                alt: PropTypes.string,
                fluid: PropTypes.shape({
                    aspectRatio: PropTypes.number.isRequired,
                    src: PropTypes.string.isRequired,
                    srcSet: PropTypes.string.isRequired,
                    sizes: PropTypes.string.isRequired,
                    base64: PropTypes.string,
                    tracedSVG: PropTypes.string,
                    srcWebp: PropTypes.string,
                    srcSetWebp: PropTypes.string,
                    media: PropTypes.string,
                    maxWidth: PropTypes.number,
                    maxHeight: PropTypes.number,
                }),
            }),
        }).isRequired,
        responsibilities: PropTypes.shape({
            title: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        experience: PropTypes.shape({
            title: PropTypes.string,
            items: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        applyLink: PropTypes.string,
    }).isRequired,
    html: PropTypes.string.isRequired,
    locationPath: PropTypes.string.isRequired,
    discipline: PropTypes.shape({
        area: PropTypes.string,
        discipline: PropTypes.string,
    }).isRequired,
};

export default DetailSection;
