import React from 'react';
import Grid, { Column, Row } from '@components/core/Grid';
import Section from '@components/core/Section';
import JobsList from '@components/JobsList';
import Text from '@components/core/Text';
import { Link } from 'gatsby';
import AnchorToSection from '@components/core/AnchorToSection';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import styles from './jobs-related.module.scss';

const JobsRelated = ({ jobsList }) => {
    const learnMore = 'Learn more about careers';
    const { md } = useBreakpoint();

    return (
        jobsList &&
        jobsList.length > 0 && (
            <Section variant="white">
                <Grid>
                    <Row>
                        <Column md="9" className={styles.container}>
                            <div className={styles.anchorSectionContainer}>
                                <Text size="h4" as="h4">
                                    Related job offers
                                </Text>
                                {md && (
                                    <AnchorToSection
                                        toPath="/careers/"
                                        sizeIcon="4"
                                        className={styles.anchorSectionText}
                                    >
                                        <Text as="span" size="paragraphSm">
                                            {learnMore}
                                        </Text>
                                    </AnchorToSection>
                                )}
                            </div>
                            <JobsList
                                sizeJobTitle="paragraphMd"
                                jobsList={jobsList}
                                background="light"
                                viewAll={false}
                            />
                            <Link to="/careers/" className={styles.mobileLink}>
                                <Text as="span" size="paragraphSm" color="blue">
                                    {learnMore}
                                </Text>
                            </Link>
                        </Column>
                    </Row>
                </Grid>
            </Section>
        )
    );
};

JobsRelated.propTypes = {
    jobsList: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default JobsRelated;
