import React from 'react';
import Grid, { Column, Row } from '@components/core/Grid';
import PropTypes from 'prop-types';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import CarouselWithPadding from '@components/Carousels/CarouselWithPadding';
import ArticleCard from '@components/Cards/Article';
import Section from '@components/core/Section';
import SectionHeader from '@components/SectionHeader';
import styles from './zemoga-benefits.module.scss';

const ZemogaBenefits = ({ variant, data: { sectionHeader, benefitsList } }) => {
    const breakpoint = useBreakpoint();
    const cardLayoutBehavior = () => {
        if (breakpoint.md) {
            return 'horizontal';
        }
        return 'vertical';
    };

    return (
        <Section variant={variant} className={styles.zemogaBenefits}>
            <Grid>
                <Row>
                    <Column className={styles.benefitsHeaderContainer}>
                        <SectionHeader
                            variant={variant}
                            eyebrow={sectionHeader.eyebrow}
                            title={sectionHeader.title}
                            summary={sectionHeader.description}
                            className={styles.sectionHeader}
                            classTitleWrapper={styles.title}
                        />
                    </Column>
                </Row>
            </Grid>
            {benefitsList.length > 0 && (
                <CarouselWithPadding infinite isFullScreen>
                    {benefitsList.map(info => {
                        const article = {
                            slug: '',
                            image: info.benefitImage,
                            topic: '',
                            title: info.title,
                            published: info.published,
                            overview: info.overview,
                        };
                        return (
                            <ArticleCard
                                key={article.published}
                                article={article}
                                sizeTitle="h3"
                                colorTitle="white"
                                layoutDistribution={cardLayoutBehavior()}
                                colorOverview="lightGrey"
                                isFullScreen
                                classCardWrapper={styles.cardWrapper}
                                classImageWrapper={styles.imageWrapper}
                                classInfo={styles.infoCard}
                            />
                        );
                    })}
                </CarouselWithPadding>
            )}
        </Section>
    );
};

ZemogaBenefits.propTypes = {
    variant: PropTypes.oneOf(['light', 'dark']),
    data: PropTypes.shape({
        sectionHeader: PropTypes.shape({
            eyebrow: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
        }),
        benefitsList: PropTypes.arrayOf(
            PropTypes.shape({
                type: PropTypes.string,
                published: PropTypes.string,
                order: PropTypes.number,
                title: PropTypes.string,
                overview: PropTypes.string,
                benefitImage: PropTypes.objectOf(PropTypes.any),
            })
        ),
    }).isRequired,
};

ZemogaBenefits.defaultProps = {
    variant: 'dark',
};

export default ZemogaBenefits;
