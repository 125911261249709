import React from 'react';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import DetailSection from '@sections/JobDetail/DetailSection';
import ZemogaBenefits from '@sections/JobDetail/ZemogaBenefits';
import JobsRelated from '@sections/JobDetail/JobsRelated';
import { formatData } from '@utils/utils';

const JobDetail = ({
    data: {
        jobDetailPageInfo: { data: jobDetailPage },
        jobDetail: { frontmatter, html },
        jobs,
        discipline,
    },
    location,
}) => {
    const seo = {
        title: (frontmatter.seo && frontmatter.seo.title) || frontmatter.title,
        description: (frontmatter.seo && frontmatter.seo.description) || '',
        image:
            (frontmatter.seo &&
                frontmatter.seo.image &&
                frontmatter.seo.image.publicURL) ||
            '',
    };
    const jobsRelated = formatData(jobs.edges);
    return (
        <Layout>
            <SEO
                title={seo.title}
                description={seo.description}
                image={seo.image}
            />
            <DetailSection
                frontmatter={frontmatter}
                html={html}
                locationPath={location}
                discipline={discipline.frontmatter}
            />
            <JobsRelated jobsList={jobsRelated} />
            <ZemogaBenefits data={jobDetailPage.someBenefits} />
        </Layout>
    );
};

export default JobDetail;

JobDetail.propTypes = {
    data: PropTypes.shape({
        jobDetailPageInfo: PropTypes.objectOf(PropTypes.any),
        jobDetail: PropTypes.objectOf(PropTypes.any),
        jobs: PropTypes.objectOf(PropTypes.any),
        discipline: PropTypes.shape({
            frontmatter: PropTypes.shape({
                area: PropTypes.string,
                discipline: PropTypes.string,
            }),
        }),
    }).isRequired,
    location: PropTypes.string.isRequired,
};

export const pageQuery = graphql`
    query JobDetail($id: String!, $discipline: String!) {
        jobDetailPageInfo: markdownRemark(
            fields: { slug: { eq: "/pages/job-detail/" } }
            frontmatter: {
                someBenefits: {
                    benefitsList: { elemMatch: { type: { eq: "benefit" } } }
                }
            }
        ) {
            data: frontmatter {
                someBenefits {
                    sectionHeader {
                        eyebrow
                        title
                        description
                    }
                    benefitsList {
                        type
                        published(formatString: "YYYY-MM-DD HH:mm:ss")
                        order
                        title
                        overview
                        benefitImage {
                            publicURL
                            childImageSharp {
                                fluid {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
        jobDetail: markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                seo {
                    title
                    description
                    image {
                        publicURL
                    }
                }
                applyLink
                title
                published(formatString: "MMMM DD YYYY")
                seniority
                discipline
                type
                status
                location
                image {
                    childImageSharp {
                        fluid(maxWidth: 880) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                responsibilities {
                    title
                    items
                }
                experience {
                    title
                    items
                }
            }
            html
        }
        discipline: markdownRemark(
            fields: { slug: { glob: "/disciplines/*" } }
            frontmatter: { discipline: { eq: $discipline } }
        ) {
            frontmatter {
                area
                discipline
            }
        }
        jobs: allMarkdownRemark(
            limit: 3
            filter: {
                fields: { slug: { glob: "/jobs/*" } }
                id: { ne: $id }
                frontmatter: { discipline: { eq: $discipline } }
            }
            sort: { order: DESC, fields: frontmatter___published }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        discipline
                        location
                        published
                    }
                    fields {
                        slug
                    }
                }
            }
        }
    }
`;
